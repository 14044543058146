import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type MessageProviderProps = {
    children?: JSX.Element;
};

const MessageProvider = ({ children }: MessageProviderProps) => {
    return (
        <>
            {children}
            <ToastContainer />
        </>
    );
};

export default MessageProvider;
